import React from "react";
import Sidebar from "../sidebar/Sidebar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CalculeCC from "../calculeCC/CalculeCC";
import Teams from "../teams/Teams";
import EstimerCM from "../estimerCM/EstimerCM";
function Admin({ token, setToken }) {
  return (
    <div className="admin">
      <Router>
        <Sidebar token={token} setToken={setToken} />
        <Switch>
          <Route
            exact
            path="/account/"
            render={(props) => <Teams token={token} />}
          />
          <Route
            exact
            path="/account/CalculeCC"
            render={(props) => <CalculeCC token={token} />}
          />
          <Route
            exact
            path="/account/EstimerCM"
            render={(props) => <EstimerCM token={token} />}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default Admin;
