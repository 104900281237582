import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./home/Home";
import Accounts from "./accounts/Accounts";
import useToken from "./usefull/useToken";
import User from "./user/User";
function App() {
  const { token, setToken } = useToken();
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            path="/account/"
            render={(props) => <Accounts token={token} setToken={setToken} />}
          />
          <Route
            path="/account/CalculeCC"
            render={(props) => <User setToken={setToken} token={token} />}
          />
        </Switch>
      </div>
    </Router>
  );
}
export default App;
