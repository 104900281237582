import React from "react";
import "./Footer.css";
import sorec from "../imgs/sorec.png";
import ensias from "../imgs/ensias.png";
import iav from "../imgs/iav.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="logos">
          <ul className="sidebar-companies-footer">
           <li>
              <a
                href="https://iav.ac.ma/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img iav" src={iav} alt="iav" />
              </a>
            </li>
            <li>
              <a
                href="https://www.sorec.ma/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img" src={sorec} alt="sorec" />
              </a>
            </li>
            <li>
              <a
                href="http://ensias.um5.ac.ma/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img" src={ensias} alt="ensias" />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-links">
          <div className="footer-links-items contact">
            <h2>Équipe</h2>
            <span>
              Pr. Mohamed Piro de l'IAV Hassan II /{" "} vetpiro@yahoo.fr              
            </span>
            <span>
                Pr. Mounia Abik de l'ENSIAS
            </span>
            <span>
                Rahma Maissou, Master Bio-MSCS / ENSIAS
            </span>
            <span>
                Ayoub Lotfi, Ingénieur / GL / ENSIAS
            </span>
            <span>
                Amine Oubaamrane, Ingénieur / GL / ENSIAS
            </span>
          </div>
        </div>
      </div>
      <div className="copy">
        <p className="text-center">
          &copy; Copyright 2021 - IAV Hassan-II En Collaboration avec ENSIAS All
          rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
