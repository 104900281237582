import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import { Alert } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { properties } from "../resources/properties";

function Upload({ show, setopen, setalert, setseverity, onHide }) {

  const override = css`
  display: block;
  margin: 20px ;
  width: 10%;
  height: auto;
  border-color: black;
  float: right;
`;
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("error");

  const [file, setFile] = useState(null);
  const filePathset = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const map = {
    NUM_ESIREMA: "NUM_ESIREMA",
    NOM_CHEVAL: "NOM_CHEVAL",
    RACE: "RACE",
    ETAT_EQUIDE: "ETAT_EQUIDE",
    PERE: "PERE",
    MERE: "MERE",
    PP: "PP",
    MP: "PM",
    PM: "MP",
    MM: "MM",
    PPP: "PPP",
    MPP: "PPM",
    PMP: "PMP",
    MMP: "PMM",
    PPM: "MPP",
    MPM: "MPM",
    PMM: "MMP",
    MMM: "MMM",
    PPPP: "PPPP",
    MPPP: "PPPM",
    PMPP: "PPMP",
    MMPP: "PPMM",
    PPMP: "PMPP",
    MPMP: "PMPM",
    PMMP: "PMMP",
    MMMP: "PMMM",
    PPPM: "MPPP",
    MPPM: "MPPM",
    PMPM: "MPMP",
    MMPM: "MPMM",
    PPMM: "MMPP",
    MPMM: "MMPM",
    PMMM: "MMMP",
    MMMM: "MMMM",
    PPPPP: "PPPPP",
    MPPPP: "PPPPM",
    PMPPP: "PPPMP",
    MMPPP: "PPPMM",
    PPMPP: "PPMPP",
    MPMPP: "PPMPM",
    PMMPP: "PPMMP",
    MMMPP: "PPMMM",
    PPPMP: "PMPPP",
    MPPMP: "PMPPM",
    PMPMP: "PMPMP",
    MMPMP: "PMPMM",
    PPMMP: "PMMPP",
    MPMMP: "PMMPM",
    PMMMP: "PMMMP",
    MMMMP: "PMMMM",
    PPPPM: "MPPPP",
    MPPPM: "MPPPM",
    PMPPM: "MPPMP",
    MMPPM: "MPPMM",
    PPMPM: "MPMPP",
    MPMPM: "MPMPM",
    PMMPM: "MPMMP",
    MMMPM: "MPMMM",
    PPPMM: "MMPPP",
    MPPMM: "MMPPM",
    PMPMM: "MMPMP",
    MMPMM: "MMPMM",
    PPMMM: "MMMPP",
    MPMMM: "MMMPM",
    PMMMM: "MMMMP",
    MMMMM: "MMMMM",
  };
  const traitementfile = (e) => {
    setLoading(true);
    e.target.blur();
    readXlsxFile(file, { map }).then(({ rows }) => {
      console.log(rows);
      fetch(`${properties.url}${properties.uploadHorses}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(rows),
      }).then((resp) => {
        if (resp.status === 201) {
          setseverity("success");
          setalert("Des chevaux ont été ajoutés !");
          setopen();
          onHide();
        } else {
          setSeverity("error");
          setAlert("L'ajout a échoué. Aucun cheval ajouté !");
          setOpen(true);
        }
        setLoading(false);
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      variant="danger"
      onShow={() => {
        setOpen(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter des chevaux
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>Fichier XLSX</Form.Label>
        <Form.Control
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
          required
          name="file"
          onChange={filePathset}
        />
      </Modal.Body>
      <Collapse in={open}>
        <Alert
          severity={severity}
          onClose={() => {
            setOpen(false);
          }}
        >
          {alert}
        </Alert>
      </Collapse>
      <Modal.Footer>
        <ClipLoader
          color={"#ffffff"}
          loading={loading}
          css={override}
          size={150}
        />
        <Button onClick={traitementfile} variant="outline-danger">
          Uploader
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default Upload;
