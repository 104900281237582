import React, { useState } from "react";
import "./EstimerCM.css";
import Button from "react-bootstrap/Button";
import { Col, Form } from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { properties } from "../resources/properties";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function EstimerCM({ token }) {
   const override = css`
  display: block;
  margin: 70px auto 10px;
  width: 10%;
  height: auto;
  border-color: burlywood;
`;

  const [loading, setLoading] = useState(false);
  const [annee, setAnnee] = useState("");
  const [anneeSelect, setAnneeSelect] = useState("");
  const [horsesByYear, setHorsesByYear] = useState(null);
  const [cm, setCm] = useState(null);
  const classes = useStyles();

  const detailCM = {
    annee: anneeSelect.substr(8),
  };
  const handleShow = (e) => {e.target.blur();}

  const estimer = (e) => {
    e.preventDefault();
    setAnnee(anneeSelect);
    setHorsesByYear(null);
    setCm(null);
    setLoading(true);
    fetch(`${properties.url}${properties.horsesByYear}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(detailCM),
    }).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          setHorsesByYear(data);
        });
      }
      setLoading(false);
    });
    fetch(`${properties.url}${properties.cm}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(detailCM),
    }).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          setCm(data);
        });
      }
    });
  };

  return (
    <>
      <div className="calculecc">
        <div className="text-table">
          <p>
            Estimer la CM <span>( Consanguinité Moyenne )</span>
          </p>
          <p>
            {token.email}
            {" / "}
            <b>
              {token.type === "0"
                ? "SuperAdmin"
                : token.type === "1"
                ? "Admin"
                : "User"}
            </b>
          </p>
        </div>
        <div className="form-estimercm">
          <Form onSubmit={estimer} className="form-calculecc">
            <Col className="form-estimercm-col">
              <Form.Label>Année</Form.Label>
              <Form.Control
                className="effect_input_inscription"
                as="select"
                defaultValue="Sélectionner l'année"
                onChange={(e) => {
                  setAnneeSelect(e.target.value);
                }}
              >
                <option disabled={true}>Sélectionner l'année</option>
                <option>Année 2009</option>
                <option>Année 2010</option>
                <option>Année 2011</option>
                <option>Année 2012</option>
                <option>Année 2013</option>
                <option>Année 2014</option>
                <option>Année 2015</option>
                <option>Année 2016</option>
                <option>Année 2017</option>
                <option>Année 2018</option>
                <option>Année 2019</option>
                <option>Année 2020</option>
              </Form.Control>
            </Col>

            <div className="calculecc-separateur" />
            <div className="calculecc-button">
              <Button
                className="calculecc-button-design"
                type="submit"
                onClick={handleShow}
                variant="dark"
              >
                Estimer
              </Button>
            </div>
            <div className="cm-estimation">
              {cm ? (
                <p className="resultaCM">
                  La Consanguinité Moyenne en {annee.substr(6)} {": "}
                  <span className="coef-cm">{cm.CM}%</span>
                </p>
              ) : (
                ""
              )}
            </div>
            <ClipLoader
              color={"#ffffff"}
              loading={loading}
              css={override}
              size={150}
            />
            <div className="horses-table">
              {horsesByYear === null ? (
                ""
              ) : (
                <TableContainer className="table-container" component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">
                          NUM_ESIREMA
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          NOM CHEVAL
                        </StyledTableCell>
                        <StyledTableCell align="left">PERE</StyledTableCell>
                        <StyledTableCell align="left">MERE</StyledTableCell>
                        <StyledTableCell align="left">CC</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {horsesByYear === null ? (
                      <tr>AUCUNE ESTIMATION</tr>
                    ) : (
                      horsesByYear.map((horse) => {
                        return (
                          <React.Fragment key={horse.NUM_ESIREMA}>
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell align="left">
                                  {horse.NUM_ESIREMA}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {horse.NOM_CHEVAL}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {horse.PERE}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {horse.MERE}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {horse.CC}
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </React.Fragment>
                        );
                      })
                    )}
                  </Table>
                </TableContainer>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EstimerCM;
