import React from "react";
import "./Chart2.css";

function Chart2({ dataPere }) {
  return (
    <div className="toto">
      <p>
        Arbre généaologique de <b>{dataPere.PERE} :</b>
      </p>
      <form id="form1">
        <div className="tree" id="FamilyTreeDiv">
          <ul>
            <li>
              <div>
                <span className="male">{dataPere.PERE}</span>
              </div>

              <ul>
                {dataPere.PP === "Nonexistent" || dataPere.PP === "" ? (
                  ""
                ) : (
                  <li>
                    <div>
                      <span className="male">{dataPere.PP}</span>
                    </div>

                    {dataPere.PPP === "Nonexistent" || dataPere.PPP === "" ? (
                      ""
                    ) : (
                      <ul>
                        <li>
                          <div>
                            <span className="male">{dataPere.PPP}</span>
                          </div>
                          {dataPere.PPPP === "Nonexistent" ||
                          dataPere.PPPP === "" ? (
                            ""
                          ) : (
                            <ul>
                              <li>
                                <div>
                                  <span className="male">{dataPere.PPPP}</span>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <span className="female">
                                    {dataPere.MPPP}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          )}
                        </li>
                        {dataPere.MPP === "Nonexistent" ||
                        dataPere.MPP === "" ? (
                          ""
                        ) : (
                          <li>
                            <div>
                              <span className="female">{dataPere.MPP}</span>
                            </div>
                            <ul>
                              <li>
                                <div>
                                  <span className="male">{dataPere.PMPP}</span>
                                </div>
                              </li>

                              <li>
                                <div>
                                  <span className="female">
                                    {dataPere.MMPP}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                )}
                {dataPere.MP === "Nonexistent" || dataPere.MP === "" ? (
                  ""
                ) : (
                  <li>
                    <div>
                      <span className="female">{dataPere.MP}</span>
                    </div>

                    {dataPere.PMP === "Nonexistent" || dataPere.PMP === "" ? (
                      ""
                    ) : (
                      <ul>
                        <li>
                          <div>
                            <span className="male">{dataPere.PMP} </span>
                          </div>
                          {dataPere.PPMP === "Nonexistent" ||
                          dataPere.PPMP === "" ? (
                            ""
                          ) : (
                            <ul>
                              <li>
                                <div>
                                  <span className="male">{dataPere.PPMP} </span>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <span className="female">
                                    {dataPere.MPMP}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          )}
                        </li>

                        {dataPere.MMP === "Nonexistent" ||
                        dataPere.MMP === "" ? (
                          ""
                        ) : (
                          <li>
                            <div>
                              <span className="female">{dataPere.MMP}</span>
                            </div>
                            {dataPere.PMMP === "Nonexistent" ||
                            dataPere.PMMP === "" ? (
                              ""
                            ) : (
                              <ul>
                                <li>
                                  <div>
                                    <span className="male">
                                      {dataPere.PMMP}{" "}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <span className="female">
                                      {dataPere.MMMP}{" "}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            )}
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </form>

      <p className="titre_arbre_mere">
        Arbre généaologique de <b>{dataPere.MERE} :</b>
      </p>
      <form id="form2">
        <div className="tree" id="FamilyTreeDiv">
          <ul>
            <li>
              <div>
                <span className="female">{dataPere.MERE}</span>
              </div>

              <ul>
                {dataPere.PM === "Nonexistent" || dataPere.PM === "" ? (
                  ""
                ) : (
                  <li>
                    <div>
                      <span className="male">{dataPere.PM}</span>
                    </div>

                    {dataPere.PPM === "Nonexistent" || dataPere.PPM === "" ? (
                      ""
                    ) : (
                      <ul>
                        <li>
                          <div>
                            <span className="male">{dataPere.PPM}</span>
                          </div>
                          {dataPere.PPPM === "Nonexistent" ||
                          dataPere.PPPM === "" ? (
                            ""
                          ) : (
                            <ul>
                              <li>
                                <div>
                                  <span className="male">{dataPere.PPPM}</span>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <span className="female">
                                    {dataPere.MPPM}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          )}
                        </li>
                        {dataPere.MPM === "Nonexistent" ||
                        dataPere.MPM === "" ? (
                          ""
                        ) : (
                          <li>
                            <div>
                              <span className="female">{dataPere.MPM}</span>
                            </div>
                            <ul>
                              <li>
                                <div>
                                  <span className="male">{dataPere.PMPM}</span>
                                </div>
                              </li>

                              <li>
                                <div>
                                  <span className="female">
                                    {dataPere.MMPM}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                )}
                {dataPere.MM === "Nonexistent" || dataPere.MM === "" ? (
                  ""
                ) : (
                  <li>
                    <div>
                      <span className="female">{dataPere.MM}</span>
                    </div>

                    {dataPere.PMM === "Nonexistent" || dataPere.PMM === "" ? (
                      ""
                    ) : (
                      <ul>
                        <li>
                          <div>
                            <span className="male">{dataPere.PMM} </span>
                          </div>
                          {dataPere.PPMM === "Nonexistent" ||
                          dataPere.PPMM === "" ? (
                            ""
                          ) : (
                            <ul>
                              <li>
                                <div>
                                  <span className="male">{dataPere.PPMM} </span>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <span className="female">
                                    {dataPere.MPMM}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          )}
                        </li>

                        {dataPere.MMM === "Nonexistent" ||
                        dataPere.MMM === "" ? (
                          ""
                        ) : (
                          <li>
                            <div>
                              <span className="female">{dataPere.MMM}</span>
                            </div>
                            {dataPere.PMMM === "Nonexistent" ||
                            dataPere.PMMM === "" ? (
                              ""
                            ) : (
                              <ul>
                                <li>
                                  <div>
                                    <span className="male">
                                      {dataPere.PMMM}{" "}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <span className="female">
                                      {dataPere.MMMM}{" "}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            )}
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </form>
    </div>
  );
}

export default Chart2;
