import React, { useState } from "react";
import "./Sidebar.css";
import { FaPowerOff } from "react-icons/fa";
import { IoIosListBox } from "react-icons/io";
import { ImCalculator } from "react-icons/im";
import { BsUpload } from "react-icons/bs";
import { Link } from "react-router-dom";
import sorec from "../imgs/sorec.png";
import ensias from "../imgs/ensias.png";
import iav from "../imgs/iav.png";
import { useHistory } from "react-router-dom";
import Upload from "../uploadFile/Upload";
import { Alert } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";

function Sidebar({ token, setToken }) {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("error");

  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const LogoutFunction = () => {
    history.push("/account/");
    setToken();
    localStorage.clear();
  };
  const [clicked, setClicked] = useState(false);
  const hundleResponsive = () => {
    if (window.innerWidth > 1070) {
    } else {
      setClicked(!clicked);
    }
  };
  window.addEventListener("resize", reportWindowSize);
  function reportWindowSize() {
    if (window.innerWidth > 1070) {
      setClicked(false);
    }
  }

  return (
    <>
      <div className="sidebar">
        <div
          className={clicked ? "sidebar-button-responsive" : "Button"}
          onClick={() => {
            setClicked(!clicked);
          }}
        >
          click
        </div>
        <div className={clicked ? "sidebar-body active" : "sidebar-body"}>
          <div className="sidebar-logo">
            <span onClick={hundleResponsive} className="logo_login1">
              <span className="logo_login1-design">ESI</span>
            </span>
          </div>
          <div className="sidebar-separator" />
          <Collapse in={open}>
            <Alert
              severity={severity}
              onClose={() => {
                setOpen(false);
              }}
            >
              {alert}
            </Alert>
          </Collapse>
          <div className="sidebar-icons">
            <ul className="icons">
              {token.type === "0" || token.type === "1" ? (
                <Link className="sidebar-icons-team" to="/account/">
                  <li>
                    <IoIosListBox />
                    <span onClick={hundleResponsive}>Liste des comptes</span>
                  </li>
                </Link>
              ) : (
                ""
              )}
              <Link className="sidebar-icons-team" to="/account/CalculeCC">
                <li>
                  <ImCalculator />
                  <span onClick={hundleResponsive}>Calculer le CC</span>
                </li>
              </Link>
              <Link className="sidebar-icons-team" to="/account/EstimerCM">
                <li>
                  <ImCalculator />
                  <span onClick={hundleResponsive}>Estimer la CM</span>
                </li>
              </Link>
              {token.type === "0" ? (
                <li
                  className="sidebar-icons-team"
                  onClick={() => {
                    setOpen(false);
                    setModalShow(true);
                  }}
                >
                  <BsUpload />
                  <span>Ajouter chevaux</span>
                </li>
              ) : (
                ""
              )}
              <li className="logout" onClick={LogoutFunction}>
                <FaPowerOff />
                <span>Se déconnecter</span>
              </li>
            </ul>
            <ul className="sidebar-companies">
              <li>
                <a
                  href="https://iav.ac.ma/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iav} alt="iav" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.sorec.ma/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="soreclogo" src={sorec} alt="sorec" />
                </a>
              </li>
              <li>
                <a
                  href="http://ensias.um5.ac.ma/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ensias} alt="ensias" className="ensias" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Upload
        show={modalShow}
        setopen={() => setOpen(true)}
        setalert={(msg) => setAlert(msg)}
        setseverity={(sev) => setSeverity(sev)}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Sidebar;
