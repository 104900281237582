import React, { useEffect, useState } from "react";
import "./Teams.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Card, Col, Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import horse from "../imgs/horse.svg";
import trash from "../imgs/trash.svg";
import remove from "../imgs/remove.svg";
import checked from "../imgs/checked.svg";
import MyVerticallyCenteredModal from "../suppression/Suppression";
import Validate from "../validate/Validate";
import Unvalidate from "../unvalidate/Unvalidate";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { Alert } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import { properties } from "../resources/properties";

const bcrypt = require("bcryptjs");
const override = css`
  display: block;
  margin: 20px auto;
  width: 70%;
  height: auto;
  border-color: black;
`;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables({ token }) {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("error");
  const [open2, setOpen2] = useState(false);
  const [alert2, setAlert2] = useState("");
  const [severity2, setSeverity2] = useState("error");

  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [emailInscription, setEmailInscription] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [organisme, setOrganisme] = useState("");
  const [type, setType] = useState("User");
  const [users, setUsers] = useState(null);
  const [user1, setUser] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowV, setModalShowV] = useState(false);
  const [modalShowU, setModalShowU] = useState(false);

  const hashedPassword = bcrypt.hashSync(password1, bcrypt.genSaltSync());
  const detailInscription = {
    prenom: prenom,
    nom: nom,
    email: emailInscription,
    password: hashedPassword,
    organisme: organisme,
    type: type,
  };
  useEffect(() => {
    if (!refresh) {
      fetch(
        `${properties.url}${token.type === "0" ? "usersadmins" : "users"}.php`
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setUsers(data);
            setUser(data[0]);
            setRefresh(true);
          });
        } else {
          setSeverity("warning");
          setAlert("La liste n'a pas pu être récupérée.");
          setOpen(true);
        }
      });
    }
  }, [refresh, token.type]);
  const inscription = (e) => {
    e.preventDefault();
    if (password1 === password2) {
      fetch(`${properties.url}${properties.registration}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(detailInscription),
      }).then((resp) => {
        if (resp.status === 201) {
          setSeverity("success");
          setAlert("Inscription réussie !");
          setOpen(true);
          setRefresh(false);

          setNom("");
          setEmailInscription("");
          setPassword2("");
          setPassword1("");
          setOrganisme("");
          handleClose();
        } else {
          setSeverity2("error");
          setAlert2("Mail déjà utilisé !");
          setOpen2(true);
        }
      });
    } else {
      setSeverity2("error");
      setAlert2("Merci de confirmer le mot de passe correctement.");
      setOpen2(true);
    }
  };
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    e.target.blur();
  };

  return (
    <div className="table">
      <div className="text-table">
        <p>Liste des comptes</p>
        <p>
          {token.email}
          {" / "}
          <b>
            {token.type === "0"
              ? "SuperAdmin"
              : token.type === "1"
              ? "Admin"
              : "User"}
          </b>
        </p>
      </div>
      <Collapse in={open}>
        <Alert
          severity={severity}
          onClose={() => {
            setOpen(false);
          }}
        >
          {alert}
        </Alert>
      </Collapse>
      <TableContainer className="table-container" component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell align="left">Nom</StyledTableCell>
              <StyledTableCell align="left">Prénom</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Organisme</StyledTableCell>
              <StyledTableCell align="left">Statut</StyledTableCell>
              <StyledTableCell align="center">Supprimer</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users ? (
              users.map((user) => (
                <React.Fragment key={user.id}>
                  {user.email === token.email ? null : (
                    <StyledTableRow key={user.id}>
                      <StyledTableCell component="th" scope="row">
                        {user.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">{user.nom}</StyledTableCell>
                      <StyledTableCell align="left">
                        {user.prenom}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {user.type === "0"
                          ? "SuperAdmin"
                          : user.type === "1"
                          ? "Admin"
                          : "User"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {user.organisme
                          ? user.organisme.toUpperCase()
                          : "Non défini".toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <ul className="status-teams">
                          {user.valide === "1" ? (
                            <li
                              onClick={() => {
                                setUser(user);
                                setModalShowU(true);
                              }}
                            >
                              <img src={checked} alt="valide" />
                            </li>
                          ) : (
                            <li
                              onClick={() => {
                                setUser(user);
                                setModalShowV(true);
                              }}
                            >
                              <img src={remove} alt="non valide" />
                            </li>
                          )}
                        </ul>
                      </StyledTableCell>
                      <StyledTableCell align="center" className="delete-cell">
                        <img
                          src={trash}
                          alt="delete"
                          className="trash"
                          onClick={() => {
                            setUser(user);
                            setModalShow(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td>
                  {" "}
                  <ClipLoader
                    color={"#ffffff"}
                    loading={true}
                    css={override}
                    size={150}
                  />{" "}
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="calculecc-button ajout_user">
        <Button className="calculecc-button-design2" onClick={handleShow}>
          Ajouter un utilisateur
        </Button>
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="header_inscription">
          <Modal.Title className="Titel_modal">
            S'inscrire
            <span onClick={handleClose} className="close_inscription">
              X
            </span>
          </Modal.Title>
          <Card.Subtitle className="mb-2 text-muted">
            C'est rapide et facile
          </Card.Subtitle>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={inscription}>
            <Form.Row className="register">
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  placeholder="Prénom"
                  required
                  onChange={(e) => {
                    setPrenom(e.target.value);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  placeholder="Nom de famille"
                  required
                  onChange={(e) => {
                    setNom(e.target.value);
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Control
              className="register effect_input_inscription"
              type="email"
              required
              placeholder="E-mail"
              onChange={(e) => {
                setEmailInscription(e.target.value);
              }}
            />
            <Form.Row className="register">
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  placeholder="Organisme"
                  required
                  onChange={(e) => {
                    setOrganisme(e.target.value);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  as="select"
                  defaultValue="User"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option>User</option>
                  {token.type === "0" ? <option> Admin </option> : ""}
                </Form.Control>
              </Col>
            </Form.Row>
            <Form.Row className="register">
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  type="password"
                  required
                  placeholder="Mot de passe"
                  onChange={(e) => {
                    setPassword1(e.target.value);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  type="password"
                  required
                  placeholder="Confirmation Mot de passe"
                  onChange={(e) => {
                    setPassword2(e.target.value);
                  }}
                />
              </Col>
            </Form.Row>
            <Collapse in={open2}>
              <Alert
                severity={severity2}
                onClose={() => {
                  setOpen2(false);
                }}
              >
                {alert2}
              </Alert>
            </Collapse>
            <Modal.Footer className="footer_button_inscription">
              <button className="btn-grad2" type="submit">
                S'inscrire
                <img src={horse} alt="logo_login" className="img_login" />
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {user1 ? (
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          user={user1}
          setRefresh={setRefresh}
        />
      ) : (
        " "
      )}
      {user1 ? (
        <Validate
          show={modalShowV}
          onHide={() => setModalShowV(false)}
          user={user1}
          setRefresh={setRefresh}
        />
      ) : (
        " "
      )}
      {user1 ? (
        <Unvalidate
          show={modalShowU}
          onHide={() => setModalShowU(false)}
          user={user1}
          setRefresh={setRefresh}
        />
      ) : (
        " "
      )}
    </div>
  );
}
