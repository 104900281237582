import React from "react";
import SuperAdmin from "../superadmin/SuperAdmin";
import Login from "../login/Login";
import User from "../user/User";
import Admin from "../admin/Admin";

function Accounts({ token, setToken }) {
  if (!token) {
    return <Login setToken={setToken} />;
  }
  if (token.type === "0") {
    return <SuperAdmin token={token} setToken={setToken} />;
  } else if (token.type === "1") {
    return <Admin token={token} setToken={setToken} />;
  } else {
    return <User token={token} setToken={setToken} />;
  }
}

export default Accounts;
