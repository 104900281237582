import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./CalculeCC.css";
import Arbre from "./Arbre";
import { properties } from "../resources/properties";

function CalculeCC({ token }) {
  const [meres, setMeres] = useState(null);
  const [peres, setPeres] = useState(null);
  const [pere, setPere] = useState("");
  const [mere, setMere] = useState("");
  const [calculCC, setCalculCC] = useState(null);
  const [show, setShow] = useState(false);
  const handleShow = (e) => {setShow(true);e.target.blur();}
  useEffect(() => {
    fetch(`${properties.url}${properties.peres}`).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          setPeres(data);
        });
      }
    });
    fetch(`${properties.url}${properties.meres}`).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          setMeres(data);
        });
      }
    });
  }, []);

  const detailCalcule = {
    PERE: pere,
    MERE: mere,
  };

  const submitcalcul = (e) => {
    e.preventDefault();
    // console.log(detailCalcule);
    fetch(`${properties.url}${properties.cc}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(detailCalcule),
    }).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          setCalculCC(data);
        });
      }
    });
  };

  return (
    <div className="calculecc">
      <div className="text-table">
        <p>
          Calculer le CC <span>( Coefficient de Consanguinité )</span>{" "}
        </p>
        <p>
          {token.email}
          {" / "}
          <b>
            {token.type === "0"
              ? "SuperAdmin"
              : token.type === "1"
              ? "Admin"
              : "User"}
          </b>
        </p>
      </div>
      <Form onSubmit={submitcalcul} className="form-calculecc">
        <Row>
          <Col>
            <Form.Label>Père</Form.Label>
            <Form.Control
              className="effect_input_inscription"
              as="select"
              defaultValue="Choisir le cheval"
              onChange={(e) => {
                setPere(e.target.value);
              }}
            >
              <option disabled={true}>Choisir le cheval</option>

              {peres
                ? peres.map((pere) => (
                    <option key={pere.toString()}>{pere}</option>
                  ))
                : ""}
            </Form.Control>
          </Col>
          <Col>
            <Form.Label>Mère</Form.Label>
            <Form.Control
              className="effect_input_inscription"
              as="select"
              defaultValue="Choisir le cheval"
              onChange={(e) => {
                setMere(e.target.value);
              }}
            >
              <option disabled={true}>Choisir le cheval</option>
              {meres
                ? meres.map((mere) => (
                    <option key={mere.toString()}>{mere}</option>
                  ))
                : ""}
            </Form.Control>
          </Col>
        </Row>
        <div className="calculecc-separateur"></div>
        <div className="calculecc-button">
          <Button
            className="calculecc-button-design"
            type="submit"
            variant="dark"
            onClick={handleShow}
          >
            Calculer
          </Button>
        </div>
        <div className="cm-estimation">
          {calculCC ? (
            <p className="resultaCC">
              Le coefficient de consanguinité :{" "}
              <span className="coef-cc">{calculCC.CC}%</span>
            </p>
          ) : (
            ""
          )}
        </div>
      </Form>
      {calculCC ? (
        <Arbre show={show} onHide={() => setShow(false)} dataPere={calculCC} />
      ) : (
        ""
      )}
    </div>
  );
}

export default CalculeCC;
