import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <div className="navbar">
      <div className="nav_contents">
        <div className="logo">ESI</div>
        <div className="item-container">
          <ul>
            <Link to="/account" className="navbar_login">
              Connexion
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
