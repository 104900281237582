export const properties = {
  url: "http://iav.access-apps.ma/apiESI/",
  peres: "peres.php",
  meres: "meres.php",
  cc: "cc.php",
  cm: "cm.php",
  horsesByYear: "horsesByYear.php",
  login: "login.php",
  registration: "registration.php",
  delete: "delete.php",
  unvalidate: "unvalidate.php",
  validate: "validate.php",
  uploadHorses: "uploadHorses.php",
};
