import React, { useState } from "react";
import { Card, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import horse from "../imgs/horse.svg";
import "./Login.css";
import { Alert } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import { properties } from "../resources/properties";

const bcrypt = require("bcryptjs");

function Login({ setToken }) {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("error");
  const [open2, setOpen2] = useState(false);
  const [alert2, setAlert2] = useState("");
  const [severity2, setSeverity2] = useState("error");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [emailInscription, setEmailInscription] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [type, setType] = useState("User");
  const [organisme, setOrganisme] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let detailsAuth = {
    email: email,
    password: password,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${properties.url}${properties.login}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(detailsAuth),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          if (bcrypt.compareSync(password, data.password)) {
            setToken(data);
          } else {
            setSeverity("error");
            setAlert("Le mot de passe est incorrect !");
            setOpen(true);
          }
        });
      } else {
        setSeverity("error");
        setAlert("Le mail est incorrect !");
        setOpen(true);
      }
    });
  };

  const hashedPassword = bcrypt.hashSync(password1, bcrypt.genSaltSync());
  let detailsInscri = {
    email: emailInscription,
    nom: nom,
    prenom: prenom,
    organisme: organisme,
    password: hashedPassword,
    type: type,
  };
  const inscription = (e) => {
    e.preventDefault();
    if (password1 === password2) {
      fetch(`${properties.url}${properties.registration}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(detailsInscri),
      }).then((resp) => {
        if (resp.status === 201) {
          setSeverity("success");
          setAlert(
            "Inscription réussie, attendez que votre compte soit validé !"
          );
          setOpen(true);
          handleClose();
        } else {
          setSeverity2("error");
          setAlert2("Mail déjà utilisé !");
          setOpen2(true);
        }
      });
    } else {
      setSeverity2("error");
      setAlert2("Merci de confirmer le mot de passe correctement.");
      setOpen2(true);
    }
  };
  return (
    <div className="login">
      <div className="login_container">
        <div className="login_container_left">
          <div className="logo_login">
            <Link className="logo_login" to="/">
              ESI
            </Link>
          </div>
          <div className="login_description">
            Ce simulateur permet d'évaluer la consanguinité chez le cheval Barbe
            au MAROC. Ainsi, vous pouvez calculer le coefficient de
            consanguinité (CC) du cheval, visualiser l'arbre généalogique, et en
            outre estimer la consanguinité moyenne (CM) annuelle des chevaux.
          </div>
        </div>
        <div className="login_container_right">
          <Card
            className="login_container_right_card"
            style={{ width: "18rem" }}
          >
            <Card.Body>
              <Card.Title className="title">Authentification</Card.Title>
              <div className="separator" />
              <Form onSubmit={handleSubmit}>
                <Form.Control
                  className="authinput"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  type="email"
                />
                <Form.Control
                  className="authinput"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  type="password"
                />
                <div className="signin_button">
                  <button className="btn-grad" type="submit">
                    Connexion
                    <img src={horse} alt="logo_login" className="img_login" />
                  </button>
                </div>
                <div className="separator" />
                <p className="newlogo">
                  Première fois sur ESI ?{" "}
                  <span
                    onClick={handleShow}
                    className="login_register text-muted"
                  >
                    S'inscrire
                  </span>
                </p>
              </Form>
              <Collapse in={open}>
                <Alert
                  severity={severity}
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  {alert}
                </Alert>
              </Collapse>
            </Card.Body>
          </Card>
        </div>
      </div>
      {/*Begin Inscription */}
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="header_inscription">
          <Modal.Title className="Titel_modal">
            S'inscrire{" "}
            <span onClick={handleClose} className="close_inscription">
              X
            </span>
          </Modal.Title>
          <Card.Subtitle className="mb-2 text-muted">
            C'est rapide et facile
          </Card.Subtitle>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={inscription}>
            <Form.Row className="register">
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  placeholder="Prénom"
                  required
                  onChange={(e) => setPrenom(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  placeholder="Nom de famille"
                  required
                  onChange={(e) => setNom(e.target.value)}
                />
              </Col>
            </Form.Row>
            <Form.Control
              className="register effect_input_inscription"
              type="email"
              required
              placeholder="E-mail"
              onChange={(e) => setEmailInscription(e.target.value)}
            />
            <Form.Row className="register">
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  placeholder="Organisme"
                  required
                  onChange={(e) => setOrganisme(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  as="select"
                  defaultValue="User"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option>User</option>
                  <option>Admin</option>
                </Form.Control>
              </Col>
            </Form.Row>
            <Form.Row className="register">
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  type="password"
                  required
                  placeholder="Mot de passe"
                  onChange={(e) => setPassword1(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  className="effect_input_inscription"
                  type="password"
                  required
                  placeholder="Confirmation Mot de passe"
                  onChange={(e) => setPassword2(e.target.value)}
                />
              </Col>
            </Form.Row>
            <Collapse in={open2}>
              <Alert
                severity={severity2}
                onClose={() => {
                  setOpen2(false);
                }}
              >
                {alert2}
              </Alert>
            </Collapse>
            <Modal.Footer className="footer_button_inscription">
              <button className="btn-grad2" type="submit">
                S'inscrire
                <img src={horse} alt="logo_login" className="img_login" />
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/* End Inscription */}
    </div>
  );
}

export default Login;
