import React from "react";
import Sidebar from "../sidebar/Sidebar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CalculeCC from "../calculeCC/CalculeCC";
import EstimerCM from "../estimerCM/EstimerCM";
// import { UserContext } from "../UserContext";
function User({ token, setToken }) {
  // const [refresh, setRefresh] = useState(false);
  return (
    <div className="admin">
      <Router>
        <Sidebar token={token} setToken={setToken} />
        <Switch>
          <Route
            exact
            path="/account/"
            render={(props) => <CalculeCC token={token} />}
          />
          <Route
            exact
            path="/account/CalculeCC"
            render={(props) => <CalculeCC token={token} />}
          />
          <Route
            exact
            path="/account/EstimerCM"
            render={(props) => <EstimerCM token={token} />}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default User;
