import React from "react";
import Navbar from "./Navbar";
import "./Home.css";
import Banner from "./Banner";
import Footer from "./Footer";

function Home() {
  return (
    <div className="home">
      {/* Navbar */}
      <Navbar />
      {/* Home */}
      <Banner />
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
