import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Chart2 from "./Chart2";
import "./Arbre.css";

function Arbre(props) {
  const validerAccount = () => {
    props.onHide();
  };
  return (
    <Modal
      className="modal-arbre"
      size="xl"
      show={props.show}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Coefficient de Consanguinité et Arbres Généaologiques
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="resultaCC">
          Le coefficient de consanguinité :{" "}
          <span className="coef-cc">{props.dataPere.CC}%</span>
        </p>
        <Chart2 className="chart" dataPere={props.dataPere} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={validerAccount}>
          Nouvelle Simulation
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default Arbre;
