import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { properties } from "../resources/properties";

function MyVerticallyCenteredModal(props) {
  const detailUserDelete = {
    id: props.user.id,
  };
  const supprimerAccount = () => {
    fetch(`${properties.url}${properties.delete}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(detailUserDelete),
    }).then((resp) => {
      if (resp.status === 200) {
        props.onHide();
        resp.json().then((data) => {
          props.setRefresh(false);
          console.log(data);
        });
      }
    });
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      variant="danger"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirmation de suppression
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Êtes-vous sûr de vouloir supprimer{" "}
          <strong>
            {props.user.nom} {props.user.prenom}
          </strong>{" "}
          ({" "}
          <em>
            {props.user.type === "0"
              ? "SuperAdmin"
              : props.user.type === "1"
              ? "Admin"
              : "User"}
          </em>{" "}
          ) ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={supprimerAccount}>
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default MyVerticallyCenteredModal;
