import React from "react";
import "./Banner.css";
import { Button } from "react-bootstrap";
import calculatrice from "../imgs/calculatrice.svg";
import { useHistory } from "react-router-dom";

function Banner() {
  const history = useHistory();
  return (
    <header className="banner">
      <div className="banner_contents">
        <p className="description">
          Ce simulateur permet d'évaluer la consanguinité chez le cheval Barbe
          au MAROC. Ainsi, vous pouvez calculer le coefficient de consanguinité
          (CC) du cheval, visualiser l'arbre généalogique, et en outre estimer
          la consanguinité moyenne (CM) annuelle des chevaux.
        </p>
        <div className="button">
          <Button
            className="textButton"
            variant="warning"
            onClick={() => history.push("/account/")}
          >
            Allons-y {"  "}
            <img
              src={calculatrice}
              alt="calculatrice"
              className="Calculatrice"
            />
          </Button>
        </div>
      </div>
      <div className="banner_fadeBottom" />
    </header>
  );
}

export default Banner;
